<template>
  <div>
    <!-- Error404 v-if="error404occured" / -->

    <!-- Hero -->
    <div class="bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-career-hero.svg`)})`}">
      <div class="container space-2">
        <div class="row align-items-lg-center">
          <div class="col-lg-6 mb-3 mb-lg-0">
            <div class="mb-4">
              <h1>{{ $t(CouponInfo.heroTitle) }}</h1>
              <p class="lead">{{ $t(CouponInfo.heroDescription) }}</p>
            </div>

            <div class="w-sm-65">
              <div class="input-group">
                <input
                  ref="heroClone"
                  readonly
                  :value="CouponInfo.couponCode"
                  class="form-control bg-white"
                  @focus="$event.target.select()"
                >
                <div class="input-group-append">
                  <button class="btn btn-block btn-primary" @click="heroCopy">{{ $t('Coupons.Common.Copy') }}</button>
                </div>
              </div>
            </div>

            <p class="small mt-3 text-danger"><i class="far fa-clock mr-1" /> {{ $t('Coupons.Common.Expiration') }} <span class="font-weight-bold">{{ $t(CouponInfo.expiryDate) }}</span>.</p>
          </div>

          <div class="col-lg-6">
            <div class="w-sm-80 w-lg-100 mx-auto">
              <img class="img-fluid" :src="require(`@/assets/svg/illustrations/${CouponInfo.heroImage}.svg`)">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Details -->
    <div class="container space-2">
      <div class="position-relative w-lg-85 mx-lg-auto mb-4">
        <div class="card shadow">
          <div class="card-body p-6">
            <div class="text-center">
              <h2 class="mb-4">{{ $t(CouponInfo.detailTitle) }}</h2>
            </div>
            <p class="mb-4">{{ $t(CouponInfo.detailDescription) }}</p>
            <div class="row">
              <div v-for="(item, index) in CouponInfo.benefits" :key="index" class="col-12 col-md-6 mb-3 mx-auto">
                <div class="border-dashed rounded p-3 h-100">
                  <div class="media align-items-center">
                    <figure class="w-100 max-w-6rem mr-4">
                      <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}.svg`)">
                    </figure>
                    <div class="media-body">
                      <p class="mb-0">{{ $t(item.detail) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="termsCollapse" class="collapse">
              <hr>
              <div>
                <h6>{{ $t('Coupons.Common.TC') }}</h6>
                <ul class="mb-0 font-size-1">
                  <li v-for="(item, index) in CouponInfo.terms" :key="index">{{ $t(item.detail) }}</li>
                </ul>
              </div>
            </div>
            <a class="link link-collapse small font-size-1 font-weight-bold pt-3 text-primary" data-toggle="collapse" href="#termsCollapse" role="button" aria-expanded="false" aria-controls="termsCollapse">
              <span class="link-collapse-default">{{ $t('Coupons.Common.TC') }}</span>
              <span class="link-collapse-active">{{ $t('Coupons.Common.ReadLess') }}</span>
              <span class="link-icon ml-1">+</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- How to Redeem? -->
    <div class="container space-2">
      <div class="text-center">
        <h2 class="mb-6">{{ $t('Coupons.Common.Redeem.Title') }}</h2>
      </div>
      <div class="row">
        <div v-for="(item, index) in Steps" :key="index" class="col-md-3 mb-4 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}`)">
            </figure>
            <h3>{{ $t(item.title) }}</h3>
            <p>{{ $t(item.description) }}</p>
          </div>
        </div>
      </div>
      <div class="text-center mt-6">
        <p class="small" v-html="$t('Coupons.Common.Redeem.Instruction')" />
      </div>
    </div>

    <!-- CTA -->
    <div class="bg-light">
      <div class="container space-1">
        <div class="row align-items-lg-center">
          <div class="col-lg-6 mb-3 mb-lg-0">
            <div class="w-60 w-lg-50 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/giftbox.svg">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-4">
              <h3>{{ $t(CouponInfo.ctaTitle) }}</h3>
              <p>{{ $t(CouponInfo.ctaDescription) }}</p>
            </div>
            <div class="w-sm-60">
              <div class="input-group">
                <input
                  ref="ctaClone"
                  readonly
                  :value="CouponInfo.couponCode"
                  class="form-control bg-white"
                  @focus="$event.target.select()"
                >
                <div class="input-group-append">
                  <button class="btn btn-block btn-primary" @click="ctaCopy">{{ $t('Coupons.Common.Copy') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- More OAH features -->
    <div class="position-relative space-top-2">
      <div class="container position-relative">
        <div class="w-md-85 w-lg-75 text-center mx-md-auto mb-5">
          <h2>{{ $t('Coupons.Common.OAHSuite.Title') }}</h2>
          <p>{{ $t('Coupons.Common.OAHSuite.Description') }}</p>
        </div>

        <div class="oah-suite-bg-1 pb-4 pb-lg-6">
          <div class="max-w-33rem w-85 mx-auto text-center">
            <router-link
              :to="{ path: `/${$route.params.lang}/api-portal`}"
              class="card card-frame shadow h-100 text-center"
            >
              <div class="card-body px-2 py-3">
                <figure class="avatar mx-auto mb-1">
                  <img src="@/assets/svg/icons/icon-44.svg" class="avatar-img">
                </figure>
                <h5 class="mb-0">{{ $t('OW01HomePage.ApiSuiteSection.ApiPortal') }}</h5>
              </div>
            </router-link>
          </div>
        </div>

        <div class="oah-suite-bg-2 pb-1 pb-md-3 pb-lg-4 pb-xl-5">
          <div class="w-90 w-sm-85 mx-auto">
            <div class="row no-gutters">
              <div v-for="(card, index) in oahSuiteCard" :key="index" class="col-6 col-sm-4 col-lg-2 px-1 mb-2 mb-lg-0">
                <router-link
                  :to="{ path: `/${$route.params.lang}/${card.url}`}"
                  class="card card-frame shadow h-100 text-center"
                >
                  <div class="card-body px-2 py-3">
                    <figure class="avatar mx-auto mb-2">
                      <img :src="require(`@/assets/svg/icons/${card.icon}.svg`)" class="avatar-img">
                    </figure>
                    <h5 class="mb-0">{{ $t(card.title) }}</h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="oah-suite-bg-3 pt-7 pb-9 bg-img-hero-center">
          <div class="max-w-33rem w-85 mx-auto text-center">
            <router-link :to="{ path: `/${$route.params.lang}/api-hub-community`}" class="card bg-secondary shadow h-100 text-center">
              <div class="card-body px-2 py-3">
                <figure class="avatar mx-auto mb-3">
                  <img src="@/assets/svg/icons/icon-52.svg" class="avatar-img">
                </figure>
                <h5 class="mb-0 text-white">{{ $t('OW01HomePage.ApiSuiteSection.Hub') }}</h5>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import 'bootstrap'
import couponList from './couponList.json'

export default {
  name: 'CouponsDetails',
  data() {
    return {
      // text: 'CouponInfo.expiryDate',
      coupon_id: '',
      CouponInfo: {},
      couponList: couponList,
      error404occured: false,
      Steps: [
        {
          title: 'Coupons.Common.Redeem.Step1.Title',
          description: 'Coupons.Common.Redeem.Step1.Description',
          icon: 'icon-48.svg'
        },
        {
          title: 'Coupons.Common.Redeem.Step2.Title',
          description: 'Coupons.Common.Redeem.Step2.Description',
          icon: 'icon-31.svg'
        },
        {
          title: 'Coupons.Common.Redeem.Step3.Title',
          description: 'Coupons.Common.Redeem.Step3.Description',
          icon: 'icon-40.svg'
        },
        {
          title: 'Coupons.Common.Redeem.Step4.Title',
          description: 'Coupons.Common.Redeem.Step4.Description',
          icon: 'icon-39.svg'
        }
      ],
      oahSuiteCard: [
        {
          title: 'OW01HomePage.ApiSuiteSection.ApiManagement',
          icon: 'icon-3',
          url: 'api-management'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.Subscription',
          icon: 'icon-73',
          url: 'api-management'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.ApiMonetization',
          icon: 'icon-5',
          url: 'api-monetization'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.ApiOps',
          icon: 'icon-71',
          url: 'apiops-cicd'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.Integration',
          icon: 'icon-72',
          url: 'apiops-cicd'
        },
        {
          title: 'OW01HomePage.ApiSuiteSection.Trust',
          icon: 'icon-47',
          url: 'trust-certification'
        }
      ]
    }
  },
  watch: {
    $route(to, from) {
      this.coupon_id = this.$route.params && this.$route.params.id
      this.error404occured = false
      this.getCouponInfo()
    }
  },
  created() {
    this.coupon_id = this.$route.params && this.$route.params.id
    this.getCouponInfo()
  },
  mounted: {

  },
  methods: {
    getCouponInfo() {
      const id = this.coupon_id
      const filteredCoupon = couponList.Items.filter(function(item) {
        return item.id === id
      })
      if (filteredCoupon.length > 0) {
        this.CouponInfo = filteredCoupon[0]
      } else {
        this.error404occured = true
      }
    },
    heroCopy() {
      this.$refs.heroClone.focus()
      document.execCommand('copy')
    },
    ctaCopy() {
      this.$refs.ctaClone.focus()
      document.execCommand('copy')
    }
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: `Promo codes and coupons | %s`,
      link: [
        { rel: 'canonical', href: `https://www.openapihub.com/coupons/${this.CouponInfo.id}` }
      ],
      meta: [
        { name: 'description', content: `Find the latest promo codes to register your OpenAPIHub Provider Plan at even lower prices. Grab the discount now!` },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: `Promo codes and coupons | OpenAPIHub` },
        { property: 'og:description', content: `Find the latest promo codes to register your OpenAPIHub Provider Plan at even lower prices. Grab the discount now!` },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: `https://www.openapihub.com/coupons/${this.CouponInfo.id}` }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.step-text {
  text-align: justify;
  text-align-last: left;
}

.oah-suite-bg-1 {
    background-image: url('../../assets/svg/components/oah-suite/bg-1.svg');
    background-position: 0 100%;
    background-size: auto;
    background-repeat: repeat-x;
}

.oah-suite-bg-2 {
    background-image: url('../../assets/svg/components/oah-suite/bg-2.svg');
    background-position: 0 100%;
    background-size: auto;
    background-repeat: repeat-x;
}

.oah-suite-bg-3 {
  background-image: url('../../assets/svg/components/oah-suite/bg-3.svg')
}

@media (max-width: 576px) {
  .oah-suite-bg-1 {
    background-image: url('../../assets/svg/components/oah-suite/bg-1-mobile.svg');
  }
}

@media (max-width: 992px) {
  .oah-suite-bg-3 {
      background-image: url('../../assets/svg/components/oah-suite/bg-3-mobile.svg');
  }
}
</style>
